import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { GameSettings, IBaseGameContext } from '../api/BaseGameContext';
import { AccountType, getFullName } from '../model/AccountType';
import { BaseGame as BaseGameModel } from '../model/Game/BaseGame';
import { isFetchError } from '../services/FetchHelper';
import AuthorChooser from './forms/AuthorChooser';
import BaseGame from './forms/games/BaseGame';
import ConfirmationForm from './forms/games/ConfirmationForm';
import CreateTranslationForm from "./forms/games/CreateTranslationForm";
import TransferOwner from './forms/TransferOwner';
import { Loading } from './Loading';
import { TopMenuButtonProps } from './Menu/TopMenuButton';
import GameDetailed from './ModelPreview/GameDetailed';
import EditGameTopMenu from './WorkShop/EditGameTopMenu';
import RequirementsJourney, { RequirementProps } from './WorkShop/RequirementsJourney';
import { GlobalModalContext, SecureContext } from './_MyFloor/MyFloorApp';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralError } from './Error/GeneralError';
import { GameContext } from '../api/Game/GameContext';

export interface SettingsProps<T, T2> {
    gameId: string;
    settings: T2;
    onClose: () => void;
    gameUpdated: (g: T) => void;
}

export type ExtraButtonProps = TopMenuButtonProps & {
    id: string
}

interface Props<T extends BaseGameModel, T2 extends GameSettings> {
    game: T | undefined;
    setGame: (game: T) => void;
    gameContext: IBaseGameContext<T>;

    newGameElementFunction?: () => void;
    newGameElementDisabled?: boolean;
    newGameElementText?: string;

    children?: ReactNode;
    SettingsNode?: React.ElementType<SettingsProps<T, T2>>;// (props: SettingsProps<T>) => JSX.Element|null;
    PreviewNode?: ReactNode;

    settingsMapper: (g: T) => T2;
    contentClass?: string;

    extraButtons?: ExtraButtonProps[];

    tempDescription: string;
    tempTitle: string;
    publishRequirements: RequirementProps[];
    publicRequirements: RequirementProps[];

    showRequirementsJourney?: boolean;
}

const EditGameComponent = <T extends BaseGameModel, T2 extends GameSettings>(props: Props<T, T2>) => {
    const {
        game, setGame, newGameElementFunction, newGameElementDisabled,
        contentClass, children, tempDescription, tempTitle,
        newGameElementText, extraButtons, gameContext, SettingsNode,
        PreviewNode, publicRequirements, publishRequirements, settingsMapper,
        showRequirementsJourney: startVisible
    } = props;
    const navigate = useNavigate();
    const { id: routeId } = useParams();
    const { popMsg, setLayoutButtons } = React.useContext(GlobalModalContext);
    const { me } = React.useContext(SecureContext);
    const { t } = useTranslation();

    const [getGame, loadingGame, errorGame] = gameContext.useGame();
    const [invokeTrash, loadingTrash, errorTrash] = gameContext.useGameTrash();
    const [invokeAddAuthor, loadingAddAuthor, errorAddAuthor] = gameContext.useAddAuthor();
    const [invokeRemoveAuthor, loadingRemoveAuthor, errorRemoveAuthor] = gameContext.useRemoveAuthor();
    const [invokeTransferOwner, loadingTransferOwner, errorTransferOwner] = gameContext.useTransferOwner();
    const [createCopy] = gameContext.useCreateCopy();
    const [restoreGame, loadingRestore, errorRestore] = GameContext.useRestoreGame();

    const trashGame = async () => {
        if (game) {
            const result = await invokeTrash(game.id);
            if (!isFetchError(result)) {
                popMsg("pop_deleted", "pop_msg_game_delete")
                navigate('/mygames', { state: { deleted: result.id } });
            }
        }
    }

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showBaseEdit, setShowBaseEdit] = useState(false);
    const [showAuthors, setShowAuthors] = useState(false);
    const [showTransferOwner, setShowTransferOwner] = useState(false);
    const [showCreateCopy, setShowCreateCopy] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        //If there is gameId and there is no routeId (a game was probably just created)
        if (game?.id && !routeId) navigate(`/workshop/${game.gameType}/edit/${game.id}`, { replace: true });
        //If there is a RouteId and it is not the gameId
        if (routeId && game?.id !== routeId) {
            getGame(routeId).then(x => !isFetchError(x) && setGame(x));
        }
    }, [game, routeId, getGame, navigate, setGame]);

    useEffect(() => {
        !errorGame && setLayoutButtons(
            <EditGameTopMenu
                game={game}
                me={me}
                showTransferOwner={() => setShowTransferOwner(true)}
                onAuthorClick={() => setShowAuthors(true)}
                showDeleteGame={() => setShowConfirmDelete(true)}
                editClick={() => setShowBaseEdit(true)}
                extraButtons={extraButtons}
                newElement={newGameElementFunction && {
                    func: newGameElementFunction,
                    text: newGameElementText,
                    disabled: newGameElementDisabled
                }}
                restoreFunc={!!game ? () => restoreGame(game.gameType, game.id).then(x => !isFetchError(x) && window.location.reload()) : undefined}
                showCopy={() => setShowCreateCopy(true)}
                onSettingsClick={SettingsNode ? () => setShowSettings(true) : undefined}
            />
        );

        return (() => setLayoutButtons(undefined));
    }, [
        game, setLayoutButtons, me, setShowTransferOwner, setShowConfirmDelete,
        extraButtons, newGameElementDisabled, newGameElementFunction, newGameElementText,
        SettingsNode, restoreGame, errorGame
    ])

    const addAuthor = async (author: AccountType) => {
        if (game) {
            const result = await invokeAddAuthor({ gameId: game.id, authorId: author.id });
            if (!isFetchError(result)) {
                setGame(result);
                popMsg("pop_added", "pop_msg_author_added", { name: getFullName(author) });
            }
        }
    }

    const removeAuthor = async (author: AccountType) => {
        if (game) {
            const result = await invokeRemoveAuthor({ gameId: game.id, authorId: author.id });
            if (!isFetchError(result)) {
                popMsg("pop_deleted", "pop_msg_author_remove", { name: getFullName(author) });
                setGame(result);
            }
        }
    }

    const transferOwner = async (account: AccountType) => {
        if (game) {
            const result = await invokeTransferOwner({ gameId: game.id, authorId: account.id });
            if (!isFetchError(result)) {
                setGame(result);
                popMsg("pop_change", "pop_change");
            }
        }
    }

    return (
        <div className={`layout-content ${contentClass || ''}`}>
            <Loading visible={loadingGame || loadingTrash || loadingRestore} />
            <GameDetailed
                altPreview={game && PreviewNode}
                gameType={gameContext.gameType}
                onClick={() => setShowBaseEdit(true)}
                game={game}
                tempDescription={t(tempDescription) ?? ""}
                tempTitle={t(tempTitle) ?? ""}
                editAuthors={() => setShowAuthors(true)}
                showStats
            />
            <GeneralError error={errorGame || errorRestore || errorTrash} />
            {children}
            {me && showBaseEdit && game &&
                <BaseGame<T>
                    game={game}
                    onClose={() => setShowBaseEdit(false)}
                    onDeleted={() => navigate("/mygames", { state: { deleted: game.id } })}
                    onGameUpdated={(g) => {
                        setGame && setGame(g);
                        setShowBaseEdit(false);
                    }}
                    gameContext={gameContext}
                    me={me}
                />
            }
            {game && showAuthors &&
                <AuthorChooser
                    chooseFunction={addAuthor}
                    loading={loadingAddAuthor || loadingRemoveAuthor}
                    onClose={() => setShowAuthors(false)}
                    chooseDelete={removeAuthor}
                    ownerId={game.ownerId}
                    authorIds={game.authorIds}
                    error={errorAddAuthor || errorRemoveAuthor}
                    visible={showAuthors}
                />
            }
            {game && showTransferOwner &&
                <TransferOwner
                    ownerId={game.ownerId}
                    loading={loadingTransferOwner}
                    chooseFunction={transferOwner}
                    onClose={() => setShowTransferOwner(false)}
                    error={errorTransferOwner}
                />
            }
            {game && showSettings && SettingsNode &&
                <SettingsNode gameId={game.id} gameUpdated={(g) => { setGame(g); setShowSettings(false); }} settings={settingsMapper(game)} onClose={() => setShowSettings(false)} />
            }
            {game && showCreateCopy &&
                <CreateTranslationForm
                    game={game}
                    invoke={(languageCode, useGoogle, keepDocument) => createCopy({ id: game.id, model: { languageCode, useGoogle, keepDocument } })}
                    onClose={() => setShowCreateCopy(false)}
                />
            }
            {game && showConfirmDelete &&
                <ConfirmationForm
                    text='confirm_delete'
                    cancelText='no'
                    confirmText='yes'
                    onCancel={() => setShowConfirmDelete(false)}
                    onConfirm={trashGame}
                    closeOnConfirm
                />
            }

            <RequirementsJourney
                openBaseEdit={() => setShowBaseEdit(true)}
                game={game}
                publishedRequirements={publishRequirements}
                publicRequirements={publicRequirements}
                startVisible={startVisible}
            />
        </div>
    )
}

export default EditGameComponent;